.project-container {
    display: flex;
    flex-direction: column;
}

.card-container {
    margin: 25px 0;
    transition: 0.2s;
    max-width: 22em;
    max-height: 175px;
    border: 1px solid var(--off-white);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    position: relative;
    background: linear-gradient(0deg, rgba(2,0,36,1) 10%, rgba(0,212,255,0.0746892507002801) 100%);
}

.card-image {
    transition: 0.2s;
    width: 100%;
    height: 100%;
    opacity: 1;
    object-fit: cover;
}

.card-title {
    transition: 0.2s;
    position: absolute;
    bottom: 8px;
    left: -300px;
    font-size: 1.5em;
    font-weight: bolder;
}

.card-date {
    transition: 0.2s;
    position: absolute;
    bottom: 40px;
    left: -100px;
}

.card-container:hover {
    transition: 0.2s;
    cursor: pointer;
    transform: scale(1.025);
    background-color: black;
}

.card-container:hover .card-title {
    transition: 0.2s;
    transform: translate(320px, 0);
}

.card-container:hover .card-date {
    transition: 0.2s;
    transform: translate(120px, 0);
}

.card-container:hover .card-image {
    transition: 0.2s;
    opacity: 0.5;
}

.details-container {
    background-color: green;
    width: 100%;
}