.App {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1200px;
  min-height: 100vh;
  text-align: center;
  background-color: var(--dark-blue);
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.4);
}

.main-container {
  padding: 0 15px;
  padding-bottom: 50px;
  display: flex;
  flex: 1;
  flex-direction: column;
}