.nav-container {
    display: flex;
    align-items: center;
    padding: 25px 50px;
    background-color: #044565;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
}

.name-title {
    transition: 0.2s;
    display: flex;
    flex-direction: column;
}

.name-title:hover {
    transition: 0.2s;
    cursor: pointer;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
}

.nav-buttons {
    margin-left: auto;
    display: flex;
}

.name {
    font-size: x-large;
}

.nav-dropdown {
    display: none;
    background-color: var(--off-white);
    color: var(--dark-blue);
    padding: 25px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.dropdown-button {
    display: none;
    margin-left: auto;
    background-color: transparent;
    color: var(--off-white);
    border: 1px solid transparent;
    padding: 10px;
}

.dropdown-button:hover {
    cursor: pointer;
    border-bottom: 1px solid var(--off-white);
}

@media only screen and (max-width: 800px) {
    .sub-title {
        display: none;
    }
    .nav-buttons {
        display: none;
    }
    .nav-dropdown {
        display: flex;
    }
    .dropdown-button {
        display: block;
    }
}