.projects-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;
    padding-top: 50px;
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    gap: 20px;
    margin-bottom: 15px;
}

.role-title {
    font-size: large;
}

.project-detail-container {
    text-align: left;
}

.back-button {
    border-bottom: 1px solid transparent;
}

.back-button:hover {
    cursor: pointer;
    border-bottom: 1px solid var(--off-white);
}